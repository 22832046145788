<template>
  <base-layout>


    <ion-content class=" has-header has-subheader has-bottom-banner" id="wcpt-session-detail">
      <div class="session-head mb-3">
        <div class="card border-0  h-100 ">
          <div class="card-body pt-2 pb-0 px-4 mx-2" v-if="sessiontype">
            <strong class="title pb-1 d-block ">
              <span v-if="session.code"> {{ session.code }} - </span>
              <span v-html="session.title"></span>
            </strong>
            <div class="time " v-if="sessiontype">
              {{ moment(session.start_time).format('dddd HH:mm') }} -
              {{ moment(session.end_time).format('HH:mm') }} {{ timezoneString }}
              <CalendarButton :session="session" :utcOffset="utcOffset" v-if="session.id && isApp "></CalendarButton>
            </div>
            <div class="time" v-if="sessiontype">
              <router-link class="d-block " style="text-decoration:none"
                           :to="'/app/sessiontypes/' + sessiontype.id">
              <span class="d-inline-block mt-1 location-text">
                 {{ sessiontype.name }}
              </span>
              </router-link>

              <router-link class="d-block " style="text-decoration:none"
                           :to="'/app/locationmap/' + session.location.id"
                           v-if="session.location">
              <span class="me-1 location-icon" v-if="mapAvailable">
                <font-awesome-icon :icon="['far', 'map-marker-alt']"/>
              </span>
                <span class="d-inline-block mt-1 location-text">
               <span v-if="session.location"> | {{session.virtual_room ? session.virtual_room : session.location.name}} </span>
              </span>
              </router-link>
              <div>
            <span v-if="session.care" data-bs-toggle="tooltip" data-bs-placement="bottom" data-bs-html="true" title="Sitzungen mit Experten aus dem Pflegeberuf" @click="showHint('Sitzungen mit Experten aus dem Pflegeberuf')">
              <font-awesome-icon :icon="['fad', 'circle']"/>
            </span>
              </div>
            </div>
          </div>


          <div class="code-wrapper mx-3 mt-2 py-4"
               :style="{'color': session.font_color, 'background-color':session.color}">
          </div>
        </div>


        <div class="session-corner-annotation" v-if="session.is_ted">
          <div class="annotation-text"> TED</div>
        </div>
      </div>
      <img  :src="'/assets/industry/' + session.code + '.png'" v-if="session.code == 'SAT03' || session.code == 'SAT07' || session.code == 'SAT09'" class="mb-1 ms-3" style="max-height:40px">


      <div v-if="session && session.outline" style="position: relative">
        <div class="description-box" style="padding: 0 20px; padding-top:0px" v-html="session.outline"></div>
        <button type="button" class="description-button btn btn-sm btn-secondary py-0" data-bs-toggle="modal"
                data-bs-target="#descriptionModal">
          zeige mehr
        </button>
        <div class="modal fade  py-5" id="descriptionModal" tabindex="-1" data-bs-backdrop="false" aria-labelledby="descriptionModalLabel" aria-hidden="true">
          <div class="modal-dialog  modal-fullscreen-sm-down modal-dialog-centered  modal-dialog-scrollable">
            <div class="modal-content note-modal">
              <div class="modal-header">
                <h5 class="modal-title" id="descriptionModalLabel">Session Description</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body" v-html="session.outline">
              </div>
            </div>
          </div>
        </div>

      </div>
      <div v-if="session">
        <div >
          <div class="details-container" v-if="hasAcceptedDisclaimer">
            <div class="container-fluid" v-if="session">
              <div class="row justify-content-center justify-content-md-start mb-1 px-2">
                <div class="col px-2" style="max-width: 200px;">
                  <FavoriteButton :fav-id="favId" v-if="session.id"></FavoriteButton>
                </div>
                <div class="col px-2" style="max-width: 200px;">
                  <NoteButton :fav-id="favId" v-if="session.id"></NoteButton>
                </div>
                <div class="col px-1 d-none d-md-block" v-if="polls && polls.length > 0">
                  <a
                      class="btn btn-secondary opacity-75 d-block px-2 px-md-1"
                      style="text-decoration: none;font-size: 0.9rem ;max-width: 200px;"
                      v-for="poll in polls" :key="poll.id"
                      :href="'#/app/polls/' + poll.id + '/sessions/' + session.id"
                  >
                    <font-awesome-icon :icon="['fas', 'poll']"/>
                    {{ wordings[currentLanguage].EVALUATE }}
                  </a>
                </div>
                <div class="col px-1 d-none d-md-block" v-if="activeSessions.indexOf(session.id) != -1" style="max-width: 200px">
                  <a
                      class="btn btn-secondary opacity-75 d-block px-2 px-md-1"
                      style="text-decoration: none;font-size: 0.9rem ;max-width: 200px;"
                      :href="'#/app/liveroom/' + session.location.id "
                  >
                    <font-awesome-icon :icon="['fas', 'camcorder']"/>
                    Watch Live
                  </a>
                </div>
                <div class="col px-1 d-none d-md-block" v-if="session.evaluation_link && activeSessions.indexOf(session.id) != -1">
                  <a
                      class="btn btn-secondary opacity-75 d-block px-2 px-md-1"
                      style="text-decoration: none;font-size: 0.9rem ;max-width: 200px;"
                      :href="session.evaluation_link " target="_blank"
                  >
                    <font-awesome-icon :icon="['fas', 'poll']"/>
                    Evaluate
                  </a>
                </div>

              </div>
              <div class="row my-2 px-2 d-md-none" v-if="polls && polls.length > 0">
                <div class="col px-2 mx-auto">
                  <a class="btn btn-secondary opacity-75 d-block px-2 px-md-1"
                     style="text-decoration: none; font-size: 0.9rem"
                     v-for="poll in polls" :key="poll.id"
                     :href="'#/app/polls/' + poll.id + '/sessions/' + session.id"
                  >
                    <font-awesome-icon :icon="['fas', 'poll']"/>
                    {{ wordings[currentLanguage].EVALUATE }}
                  </a>
                </div>

              </div>
              <div class="col px-1 d-md-none mb-1" v-if="activeSessions.indexOf(session.id) != -1">
                <a
                    class="btn btn-secondary opacity-75 d-block px-2 px-md-1"
                    style="text-decoration: none;font-size: 0.9rem"
                    :href="'#/app/liveroom/' + session.location.id "
                >
                  <font-awesome-icon :icon="['fas', 'camcorder']"/>
                  Watch Live
                </a>
              </div>

              <div class="col px-1 d-md-none" v-if="session.evaluation_link && activeSessions.indexOf(session.id) != -1">
                <a
                    class="btn btn-secondary opacity-75 d-block px-2 px-md-1"
                    style="text-decoration: none;font-size: 0.9rem ;"
                    :href="session.evaluation_link " target="_blank"
                >
                  <font-awesome-icon :icon="['fas', 'poll']"/>
                  Evaluate
                </a>
              </div>
            </div>


          </div>
          <hr style="border-width: 1px !important;">
          <div class="container-fluid " v-if="hasAcceptedDisclaimer">
            <div class="row row-cols-1  justify-content-start"
                 :class="{'row-cols-md-2': qandaVisible || votingVisible}">
              <div class="col-12 col-md-8 px-0 content-left" :class="{'col-md-8': qandaVisible || votingVisible}">
                <div class="card border-0">
                  <div class="card-body py-0">

                    <!--div class="stream-container"
                         v-if="((session.liveLink && liveSessionActive) || activeSessions.indexOf(session.id) != -1)">
                      <div class="stream">
                        <iframe
                            v-if="((isLiveSession && liveSessionActive) || activeSessions.indexOf(session.id) != -1 )"
                            :src="session.liveLink"
                            style="position:absolute;top:0;left:0;width:100%;height:100%;"
                            frameborder="0"
                            allow="autoplay; fullscreen"
                            allowfullscreen
                        ></iframe>
                      </div>
                    </div-->
                    <div class="stream-container"
                         v-if="session.odLink  &&  (!liveSessionActive)"
                    >
                      <div class="stream">
                        <iframe
                            v-if="session.odLink"
                            :src="session.odLink"
                            style="position:absolute;top:0;left:0;width:100%;height:100%;"
                            frameborder="0"
                            allow="autoplay; fullscreen"
                            allowfullscreen
                        ></iframe>
                      </div>

                    </div>
                  </div>
                </div>
                <div class="d-block d-md-none mb-3">
                  <user-view
                      :session-id="session.id"
                      v-if="loaded"
                      style="width: 100%;  display:inline-block; vertical-align: top;overflow: hidden;overflow-x:hidden;"
                  ></user-view>
                </div>
                <div>

                  <div class="container-fluid p-0 px-2 mt-md-2">



                    <div class="px-2" style="border:none"
                         v-if="presentations && presentations.length > 0">
                      <h3 class=" mt-2 mb-0">{{ wordings[currentLanguage].PRESENTATION_HEADER }}</h3>
                    </div>
                    <presentation-list :presentations="presentations"></presentation-list>

                    <div class="px-2" style="border:none" v-if="persons && persons.length > 0">
                      <h3 v-if="persons.length > 1" class=" mt-2 mb-0">Vorsitz</h3>
                      <h3 v-else class=" mt-2 mb-0">Vorsitz</h3>
                    </div>
                    <div
                        class="row g-1 row-eq-height justify-content-start d-none d-md-flex">
                      <template v-for="person in persons" :key="person.id">
                        <div class="col person-wrapper">
                          <person-item :person="person"></person-item>
                        </div>

                      </template>
                    </div>
                    <div
                        class="row row-cols-1 row-cols-md-4 g-2 ps-md-2 g-md-3 row-eq-height justify-content-start d-flex d-md-none">
                      <template v-for="person in persons" :key="person.id">
                        <person-item :person="person"></person-item>
                      </template>
                    </div>

                    <div class="px-2" style="border:none" v-if="speakers && speakers.length > 0">
                      <h3 class="mt-2 mb-0">Referierende</h3>
                    </div>
                    <div
                        class="row g-1 row-eq-height justify-content-start d-none d-md-flex">
                      <template v-for="person in speakers" :key="person.id">
                        <div class="col person-wrapper">
                          <person-item :person="person"></person-item>
                        </div>

                      </template>
                    </div>
                    <div
                        class="row row-cols-1 row-cols-md-4 g-2 ps-md-2 g-md-3 row-eq-height justify-content-start d-block d-md-none">
                      <template v-for="person in speakers" :key="person.id">
                        <person-item :person="person"></person-item>
                      </template>
                    </div>
                  </div>


                </div>
              </div>
              <div class="col-12 col-md-4 d-none d-md-block px-0" :class="{'col-md-4': qandaVisible || votingVisible}">
                <div class="card border-0">
                  <div class="card-body p-0 m-0">
                    <IonSegment mode="md" :value="activeTab" class="interactive-segment d-none">
                      <IonSegmentButton class="interactive-segment-btn"
                                        value="interaction"
                                        @click="changeTab('interaction')" v-if="!liveStreamHasEnded"
                      >
                        <IonLabel>{{ wordings[currentLanguage].INTERACTIVE }}</IonLabel>
                      </IonSegmentButton>
                    </IonSegment>
                    <div>
                      <user-view v-on:qanda-active="toggleQanda" v-on:voting-active="toggleVoting"
                                 :session-id="session.id"
                                 v-if="loaded"
                                 style="width: 100%;  display:inline-block; vertical-align: top;overflow: hidden;overflow-x:hidden;"
                      ></user-view>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </ion-content>
  </base-layout>
</template>

<script>
import {alertController, IonContent, IonSegment, IonSegmentButton} from "@ionic/vue";
import {defineComponent} from "vue";
import {mapActions, mapGetters, mapState} from "vuex";
import moment from "moment";
import PresentationList from "@/components/PresentationList";
import UserView from "@/components/interactive/UserView";

const config = require('@/config');
//import Forum from "@/components/interactive/Forum";
import FavoriteButton from "@/components/favorite/FavoriteButton";
import CalendarButton from "@/components/CalendarButton";

import NoteButton from "@/components/favorite/NoteButton";
import PersonItem from "@/components/PersonItem";
//moment.locale("de");

export default defineComponent({
  name: "SessionDetail",
  data() {
    return {
      session: {},
      loaded: false,
      isLiveSession: false,
      persons: [],
      speakers: [],
      presentations: [],
      activeTab: "interaction",
      activeSection: 'details',
      interval: null,
      sessionHasStarted: false,
      sessionHasEnded: false,
      liveStreamHasStarted: false,
      liveStreamHasEnded: false,
      liveSessionActive: false,
      zoomSessionActive: false,
      actualTime: "",
      sessiontype: null,
      favId: null,
      mapAvailable: false,
      isRestrictedAccess: false,
      qandaVisible: false,
      votingVisible: false,
      showTab: 'Moderation',
      utcOffset: '+02:00',
      timezoneString: 'CET',
      polls: [],
      isApp: config.is_app,
      hasAcceptedDisclaimer: false
    };
  },
  components: {
    IonContent,
    "presentation-list": PresentationList,
    "user-view": UserView,
    /*forum: Forum,*/
    FavoriteButton,
    IonSegment,
    IonSegmentButton,
    NoteButton,
    PersonItem,
    CalendarButton

  },
  computed: {
    ...mapGetters('auth', ['user']),
    ...mapState('sessions', ['activeSessions']),
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion']),
  },
  methods: {
    ...mapActions("sessions", [
      "getSingleSession",
      "getPresentationList",
      "getSpeakersInSession",
      "getSessionPersons",
      "getSessionType"
    ]),
    ...mapActions("settings", [
      "getSetting",
    ]),
    ...mapActions("video", ["stopVideos"]),
    ...mapActions('googleanalytics', ['trackWithLabel']),

    ...mapActions("maps", ["getMapFromLocation"]),
    ...mapActions("cmetracking", [
      "uploadTrackingEvents",
      "trackEvent",
    ]),
    ...mapActions('polls', ['getPollForState']),
    toggleQanda(status) {
      this.qandaVisible = status;
    },
    toggleVoting(status) {
      this.votingVisible = status;
    },

    async getServerTime() {
      try {
        let time_url =
            "https://mantel-api.mobile.documedias.systems/v2/timestamp";
        return (await this.axios.get(time_url)).data;
      } catch (err) {
        console.log(err);
        return null;
      }
    },

    changeTab(section) {
      this.activeTab = section;
    },

    getForum(refId) {
      this.$socketInt.emit("getForum", {
        congressId: this.currentConference,
        refId: refId
      });
    },
    checkTime(session) {
      let dateNow;
      let sessionStart;
      let sessionEnd;
      let liveSessionStart;
      let liveSessionEnd;
      let zoomSessionEnd;
      dateNow = new Date(moment.utc());//new Date(this.actualTime);
      sessionStart = new Date(session.start_time + this.utcOffset);
      sessionEnd = new Date(session.end_time + this.utcOffset);

      this.sessionHasStarted = dateNow > sessionStart;
      this.sessionHasEnded = dateNow > sessionEnd;
      liveSessionStart = moment(sessionStart).subtract(5, "minutes");
      liveSessionEnd = moment(sessionEnd).add(10, "minutes");
      zoomSessionEnd = moment(sessionEnd).add(10, "minutes");
      this.liveStreamHasStarted = dateNow >= liveSessionStart;
      this.liveStreamHasEnded = dateNow >= liveSessionEnd;
      this.liveSessionActive =
          dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
      this.zoomSessionActive =
          dateNow >= liveSessionStart && dateNow <= zoomSessionEnd;
      this.interval = setInterval(() => {
        this.actualTime = moment(this.actualTime).add(5, "seconds");
        dateNow = moment(this.actualTime);
        sessionStart = new Date(session.start_time + this.utcOffset);
        sessionEnd = new Date(session.end_time + this.utcOffset);
        this.sessionHasStarted = dateNow > sessionStart;
        this.sessionHasEnded = dateNow > sessionEnd;

        liveSessionStart = moment(sessionStart).subtract(5, "minutes");
        liveSessionEnd = moment(sessionEnd).add(10, "minutes");
        zoomSessionEnd = moment(sessionEnd).add(10, "minutes");
        this.liveStreamHasStarted = dateNow >= liveSessionStart;
        this.liveStreamHasEnded = dateNow >= liveSessionEnd;
        this.liveSessionActive =
            dateNow >= liveSessionStart && dateNow <= liveSessionEnd;
        this.zoomSessionActive =
            dateNow >= liveSessionStart && dateNow <= zoomSessionEnd;
      }, 5000);
    },

    checkRestriction() {
      let now = new moment().toISOString();
      if(!this.user || (this.user && this.user.user_no_virt_access)){
        this.isRestrictedAccess = true
      }
      if (this.user && this.user.extra && this.user.extra[this.currentConference]) {
        let extra = this.user.extra[this.currentConference];
        //let full = extra.tn_category == 'Full';
        let pfd = this.user.user_pfd;
        let full = this.user.user_full;
        let qm = this.user.user_qm;
        let pfdSessions = ['PFD01', 'PFD02', 'PFD03', 'PFD04'];
        let qmSessions = ['QM1', 'QM2', 'QM3', 'QM4','QM MTE-01',
          'QM MTE-02',
          'QM MTE-03',
          'QM MTE-04',
          'QM MTE-05'];
        this.isRestrictedAccess = false;

        if (full) {
          this.isRestrictedAccess = false;
        }
        if (this.session != null && this.session.id) {
          if (pfd && pfdSessions.indexOf(this.session.code) == -1) {
            this.isRestrictedAccess = true;
          }
          if (qm && qmSessions.indexOf(this.session.code) == -1) {
            this.isRestrictedAccess = true;
          }

        } else {
          if (pfd || qm) {
            this.isRestrictedAccess = true;
          }
        }

      }
    },
    async showDisclaimer(session) {
      if (session.disclaimer && session.disclaimer.length > 10) {
        const alert = await alertController
            .create({
              cssClass: 'my-custom-class',
              header: session.title,
              message: '<strong>' + session.disclaimer + '</strong>',
              buttons: [
                {
                  text: 'Decline',
                  role: 'cancel',
                  cssClass: 'secondary',
                  id: 'cancel-button',
                  handler: blah => {
                    console.log('Confirm Cancel:', blah);
                    this.$router.go(-1)
                  },
                },
                {
                  text: 'Accept',
                  id: 'confirm-button',
                  handler: () => {
                    this.trackWithLabel(session.title);
                    this.hasAcceptedDisclaimer = true;
                    let trackData = {
                      user: this.user,
                      item: this.session,
                      event: 'enter',
                      type: 'session',
                      itemId: this.session.id,
                    }
                    this.trackEvent(trackData)
                  },
                },
              ],
            });
        return alert.present();
      } else {
        this.hasAcceptedDisclaimer = true;
        let trackData = {
          user: this.user,
          item: this.session,
          event: 'enter',
          type: 'session',
          itemId: this.session.id,
        }
        this.trackEvent(trackData)
      }

    },

    async showHint(message) {
      const alert = await alertController
          .create({
            cssClass: 'my-custom-class',
            header: 'Info',
            message: '<strong>' + message + '</strong>',
            buttons: [
              {
                text: 'OK',
                id: 'confirm-button',
                handler: () => {
                },
              },
            ],
          });
      return alert.present();
    },
  },
  async created() {
    this.moment = moment;
  },
  watch: {
    '$route': {
      immediate: true,
      async handler(to) {
        if (to.name == 'Session Detail') {
          this.hasAcceptedDisclaimer = false;

          this.utcOffset = await this.getSetting('timezone');
          this.timezoneString = await this.getSetting('timezone_label');
          console.log('UTC', this.utcOffset, this.timezoneString)
          this.session = await this.getSingleSession(to.params.id);
          this.favId = 'sessions/' + this.session.id;
          this.sessiontype = await this.getSessionType(this.session.id)
          console.log('fav from session', this.favId)
          //this.checkRestriction();
          //this.isRestrictedAccess = false;
          if (this.session.location) {
            this.mapAvailable = await this.getMapFromLocation(this.session.location.id);
          }
          this.persons = await this.getSessionPersons(this.session.id);
          this.speakers = await this.getSpeakersInSession(this.session.id);
          let presentations = await this.getPresentationList(this.session.id);
          this.presentations = presentations.sort((a, b) => {
            return a.order - b.order;
          });
          this.loaded = true;

          if (this.persons.length == 0) {
            this.showTab = 'Presentations'
          }

          this.polls = await this.getPollForState(window.location);
          this.trackWithLabel(this.session.title)
          this.getForum(to.params.id);
          let links;
          try {
            let external = this.session.external_link.replace(/'/g, '"');
            links = JSON.parse(external);
          } catch (err) {
            links = this.session.external_link;

          }
          if (links.live && links.live.indexOf('autoplay') != -1) {
            links.live = links.live.replace('autoplay=0', 'autoplay=1')
          }

          this.session.liveLink = links.live;
          this.session.odLink = links.od;

          this.isLiveSession = this.session.location && this.session.location.id != 'da7ad0c0-3ed1-4500-1302-080000000018' ? true : false;
          this.actualTime = await this.getServerTime();
          this.checkTime(this.session);

          await this.showDisclaimer(this.session)

          if(this.session.outline && this.session.outline.indexOf('To visit the ePosters') == 0){
            this.session.outline = "To visit the ePosters <a href='#/app/collections/da7ad0c0-3ed1-4500-1350-010000000266'>Click here</a>"
          }
          if(this.session.outline && this.session.outline.indexOf('To visit the on-demand library') == 0){
            this.session.outline = "To visit the on-demand library <a href='#/app/collections/da7ad0c0-3ed1-4500-1350-010000000253'>Click here</a>"

          }

        } else {
          this.session.liveLink = null;
          this.stopVideos();
          let trackData = {
            user: this.user,
            item: this.session,
            event: 'leave',
            type: 'session',
            itemId: this.session.id,
          }
          this.trackEvent(trackData)
        }
      }
    },
    updateFinished: async function (newVal) {
      if (newVal) {
        this.utcOffset = await this.getSetting('timezone');
        this.timezoneString = await this.getSetting('timezone_label');
        this.session = await this.getSingleSession(this.$route.params.id);
        this.favId = 'sessions/' + this.session.id;
        //this.checkRestriction();
        //this.isRestrictedAccess = false;
        if (this.session.location) {
          this.mapAvailable = await this.getMapFromLocation(this.session.location.id);
        }
        this.sessiontype = await this.getSessionType(this.session.id)
        this.getForum(this.$route.params.id);
        let links;
        try {
          let external = this.session.external_link.replace(/'/g, '"');
          links = JSON.parse(external);
        } catch (err) {
          links = this.session.external_link;

        }
        if (links.live && links.live.indexOf('autoplay') != -1) {
          links.live = links.live.replace('autoplay=0', 'autoplay=1')
        }

        this.session.liveLink = links.live;
        this.session.odLink = links.od;
        this.isLiveSession = this.session.location && this.session.location.id != 'da7ad0c0-3ed1-4500-1302-080000000018' ? true : false;
        this.actualTime = await this.getServerTime();
        this.checkTime(this.session);

        this.persons = await this.getSessionPersons(this.session.id);
        this.speakers = await this.getSpeakersInSession(this.session.id);
        let presentations = await this.getPresentationList(this.session.id);
        this.presentations = presentations.sort((a, b) => {
          return a.order - b.order;
        });
        this.loaded = true;

        if (this.persons.length == 0) {
          this.showTab = 'Presentations'
        }

      }
    },
  }
});
</script>
<style lang="scss">

.person-wrapper {
  max-width: 190px;
}


.details-container {
  line-height: 40px;
}

.location-box {
  padding-left: 15px;
  border-left: 0.3rem solid;
}

.type-detail {
  color: grey
}

.location-icon {
  color: black !important
}

.location-text {
  text-decoration: none !important;
  color: #000 !important;
}

.interactive-segment {
  --background: #fff;
}

.interactive-segment-btn {
  --background: #fff !important;
  --color: 3 d5a59 !important;
  --color-checked: #3d5a59 !important;
  --background-checked: #fff !important;
}

.session-head {
  display: inline-block;
  border-bottom: none;
  position: relative;

  .code-wrapper {
    position: absolute;
    left: 0px;
    height: 100%;
    width: 8px;
    border-radius: 5px;
  }


  .item-header-gradient {
    position: absolute;
    //z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: url('../../assets/shadow.png') no-repeat bottom center;
    background-size: cover;
  }

  .title {
    font-size: 1.1rem;
    line-height: 1.3rem;
  }

  .type {
    font-size: 0.9rem;
    display: block;
    line-height: 1rem;

    strong {
      display: inline-block;
      font-size: 0.7rem;
    }
  }

  .time {
    font-size: 0.9rem;
  }
}

$main-color: #ba2526;

.session-corner-annotation {
  background-image: linear-gradient(to right, rgb(197, 50, 50), rgb(179, 68, 68));
  position: absolute;
  top: -45px;
  right: -115px;
  width: 180px;
  height: 150px;
  -webkit-transform: rotate(45deg);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.annotation-text {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  color: white;
}

.content-left {
  .action-buttons {
    .actn-icon {
      font-size: 1.3rem !important;
    }

    .actn-text {
      font-size: 0.7rem !important;
      line-height: 0.8rem !important;
    }
  }

  .stream-container {
    width: 100%;
    //max-width: 1100px;

    .stream {
      position: relative;
      width: 100%;
      aspect-ratio: 16/9;

      .info-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 3px dashed grey;

        .info {
          flex: 0 0 400px;
          font-size: 2rem;
          line-height: 2.2rem;
          color: grey;
          font-weight: bold;
        }
      }
    }
  }
}

@media(max-width: 767px) {
  .person-item {
    max-width: 100%;
    border-radius: 0;
    border: none;
  }

  .description-box {
    font-size: 0.9rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }


  .description-button {
    position: relative;
    left: 20px;
    top: 0px;
    background: $main-color;
    border: none;
    margin-bottom:10px
    // top: 0px;
    //opacity: 40% !important;
  }
}

@media(min-width: 768px) {
  .description-button {
    display: none !important;
  }
}

</style>
