<template>
  <div v-if="!alreadySaved" class="calendar-button btn btn-sm btn-secondary py-0" style="font-size: 0.9rem;"
       @click="saveToCalendar()">
    <font-awesome-icon :icon="['far', 'calendar']"/>
  </div>
  <div v-if="alreadySaved" class="calendar-button btn btn-sm btn-secondary py-0"
       style="font-size: 0.9rem; background: green !important" @click="openInCalendar()">
    <font-awesome-icon :icon="['far', 'calendar']"/>
  </div>

</template>

<script>
import {defineComponent} from "vue";

import {mapActions, mapState} from "vuex";

const config = require('@/config');

export default defineComponent({
  data() {
    return {
      startDate: new Date(this.session.start_time + this.utcOffset), // beware: month 0 = january, 11 = december
      endDate: new Date(this.session.end_time + this.utcOffset),
      title: this.stripBadIOSChars(this.session.title),
      eventLocation: this.fullName,
      notes: this.session.outline ? this.stripBadIOSChars(this.session.outline) : this.stripBadIOSChars(this.session.title),
      alreadySaved: false,
    };
  },
  components: {},
  props: ['session', 'utcOffset'],
  computed: {
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion'])
  },
  methods: {
    ...mapActions("personalDB", ["connectPersonalDB", 'getItem', 'getItems', 'prepareFavoriteList', 'saveItem', 'togglePresentation', 'toggleSession']),


    stripBadIOSChars(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, ' '); //strip new lines return str; }
    },
    saveToCalendar() {
      let success = function () {
        alert(this.wordings[this.currentLanguage].CALENDAR_SAVE);
      };
      let error = function (message) {
        alert(this.wordings[this.currentLanguage].CALENDAR_SAVE_ERROR + " " + message);
      };
      window.plugins.calendar.createEventInteractively(this.title, this.eventLocation, this.notes, this.startDate, this.endDate, success, error);
    },

    checkCalendar() {
      let self = this;
      let success = function (message) {
        console.log(message)
        if (JSON.stringify(message) != '[]') {
          self.alreadySaved = true;
        } else {
          self.alreadySaved = false
        }
      };

      let error = function (message) {
        console.log("error" + message)
      };

      window.plugins.calendar.findEvent(this.title, this.eventLocation, this.notes, this.startDate, this.endDate, success, error);


    },

    openInCalendar() {
      let success = function () {
        console.log("opening calendar")
      };
      let error = function (message) {
        console.log("calendar could not be opened " + message)
      };
      let d = this.startDate;
      window.plugins.calendar.openCalendar(d, success, error);
    }
  },

  created() {
    this.checkCalendar();

    if (this.session.location) {
      this.eventLocation = this.session.location.name;
    }
  },

});
</script>

<style lang="scss">
.calendar-button {
  position: relative;
  margin-left: 10px;
  width: 40px;
  background: lightgrey;
  border: none;
  // top: 0px;
  //opacity: 40% !important;
}
</style>
